import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c(VAvatar,{staticClass:"elevation-3",attrs:{"color":_vm.infos.color,"size":"38"}},[_c(VIcon,{staticClass:"rounded-0",attrs:{"size":"24","color":"white"}},[_vm._v(" "+_vm._s(_vm.infos.icon)+" ")])],1),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n1",attrs:{"small":"","icon":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,{staticClass:"text-no-wrap text--primary mt-3"},[_c('p',{staticClass:"font-weight-semibold text-sm mb-1"},[_vm._v(" "+_vm._s(_vm.infos.statTitle)+" ")]),_c('div',{staticClass:"d-flex align-end flex-wrap"},[_c('span',{staticClass:"font-weight-semibold text-2xl me-1 mb-2",attrs:{"id":_vm.infos.id}},[_vm._v(_vm._s(_vm.infos.statistics.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })))])]),_c('p',{staticClass:"text-ls text--secondary mb-0"},[_vm._v(" "+_vm._s(_vm.infos.subtitle)+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }