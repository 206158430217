<template>
  <v-card>
    <v-card-title class="align-start">
      <v-avatar
        :color="infos.color"
        size="38"
        class="elevation-3"
      >
        <v-icon
          size="24"
          color="white"
          class="rounded-0"
        >
          {{ infos.icon }}
        </v-icon>
      </v-avatar>
      <v-spacer></v-spacer>
      <v-btn
        small
        icon
        class="me-n3 mt-n1"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary mt-3">
      <p class="font-weight-semibold text-sm mb-1">
        {{ infos.statTitle }}
      </p>

      <div class="d-flex align-end flex-wrap">
        <span
          :id="infos.id"
          class="font-weight-semibold text-2xl me-1 mb-2"
        >{{ infos.statistics.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}</span>
        <!-- <span
          class="percentage text-xs mb-2"
          :class="checkChange(infos.change) ? 'success--text':'error--text'"
        > {{ infos.change }}</span> -->
      </div>
      <p class="text-ls text--secondary mb-0">
        {{ infos.subtitle }}
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    infos: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiDotsVertical,
      },
    }
  },

  // async created() {
  //   await this.counterDataStatistics(0, this.infos.statistics)
  // },

  methods: {
    counterDataStatistics(initial, maximum) {
      const number = document.getElementById(this.infos.id)
      if (initial > maximum) return
      setTimeout(() => {
        number.innerHTML = initial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        this.counterDataStatistics(initial + (maximum / 100), maximum)
      }, 5)
    },
    checkChange(value) {
      const firstChar = value.charAt(0)
      if (firstChar === '+') {
        return true
      }

      return false
    },
  },
}
</script>
<style lang="scss" scoped>
.percentage {
  top: -8px;
  position: relative;
}
</style>
