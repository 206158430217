<!-- cSpell: disable -->
<template>
  <div>
    <v-card class="pb-3 mb-4">
      <v-card-title class="align-start">
        <span class="font-weight-semibold">Estatísticas</span>
      </v-card-title>

      <v-card-subtitle class="mb-4 mt-n1">
        <span class="font-weight-semibold text--primary me-1">Dados das vendas de consórcio 💰</span>
      </v-card-subtitle>

      <v-row class="ml-3">
        <v-col cols="3">
          <v-autocomplete
            v-model="itemSearch"
            dense
            outlined
            :items="listRegionsItems"
            label="Filial"
          />
        </v-col>
        <v-col cols="3">
          <v-menu
            v-model="dateMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="itemSearchDate"
                dense
                outlined
                label="Mês referência"
                hide-details
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="itemSearchDate"
              type="month"
              width="270"
              locale="pt-BR"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="1"
          class=""
        >
          <v-btn
            variant="tonal"
            color="info"
            @click="
              getDataSalesInputSelected(),
              getSalesMotocyclesMonthly(),
              getDataCloudSalesGoods(),
              getDataSalesAccess(),
              getDataServices(),
              getDataYearly()
            "
          >
            <v-icon size="30">
              {{ icon.mdiSendCircleOutline }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-card-text>
        <v-row>
          <v-col
            v-for="data in dataCommission"
            :key="data.value"
            class="d-flex align-center"
          >
            <statistics-payments
              :key="data.value"
              :infos="data"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card>
      <v-row class="d-flex mt-5">
        <v-col md="6">
          <v-card-text>
            <v-card-title class="align-start">
              <span class="font-weight-semibold">Vendas</span>
            </v-card-title>

            <v-card-subtitle class="mb-8 mt-n1">
              <span class="font-weight-semibold text--primary me-1">Curva de valores de vendas 💸</span>
            </v-card-subtitle>
            <chart-commission-yearly
              :key="dataValueSalesImp"
              :labels="labels"
              :values-imp="dataValueSalesImp"
              :values-aca="dataValueSalesAca"
              :values-gra="dataValueSalesGra"
            />
          </v-card-text>
        </v-col>
        <v-col md="6">
          <v-card-text>
            <v-card-title class="align-start">
              <span class="font-weight-semibold">Motos</span>
            </v-card-title>

            <v-card-subtitle class="mb-8 mt-n1">
              <span class="font-weight-semibold text--primary me-1">Modelos mais vendidos no mês 🤩</span>
            </v-card-subtitle>
            <!-- <chart-sales
              :key="labelsVehicles"
              :labels="labelsVehicles"
              :quantity="quantityVehicles"
            /> -->

            <chartjs-component-bar-chart
              :key="latestBarChart.data.labels"
              :height="440"
              :data="latestBarChart.data"
              :options="latestBarChart.options"
            />
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-6">
      <v-col
        v-for="statistics in statisticsCardsData"
        :key="statistics.id"
      >
        <statistics-card
          :key="statistics.statistics"
          :infos="statistics"
        />
      </v-col>
    </v-row>

    <chart-sales-yearly
      :key="(itemsGraphSalesYearlyCurrent, itemsGraphSalesYearlyOld)"
      :labels="labels"
      :data="itemsGraphSalesYearlyCurrent"
      :old="itemsGraphSalesYearlyOld"
      :region="itemSearch"
    />
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import {
  mdiCashMultiple,
  mdiCogs,
  mdiCurrencyUsd,
  mdiGaugeFull,
  mdiMotorbikeElectric,
  mdiSendCircleOutline,
  mdiTrendingUp,
  mdiWatchVibrate,
} from '@mdi/js'
import axios from 'axios'
import ChartjsComponentBarChart from '../sigma/Charts/ChartjsComponentBarChart.vue'
import ChartCommissionYearly from './charts/ChartCommissionYearly.vue'
import StatisticsCard from './statistics/StatisticsCard.vue'

import ChartSalesYearly from './charts/ChartSalesYearly.vue'
import StatisticsPayments from './statistics/StatisticsPayments.vue'

export default {
  components: {
    ChartSalesYearly,
    ChartjsComponentBarChart,
    ChartCommissionYearly,
    StatisticsCard,
    StatisticsPayments,
  },
  data() {
    return {
      // dados do gráfico de motos
      chartColors: {},
      latestBarChart: {},

      // dados do gráfico de motos

      dataValueSalesImp: [],
      dataValueSalesAca: [],
      dataValueSalesGra: [],
      itemSearchDate: '',
      dateMenu: false,
      dataSalesGeneral: '',
      itemSearch: '',
      listRegionsItems: ['IMPERATRIZ', 'AÇAILÂNDIA', 'GRAJAÚ'],
      icon: {
        mdiTrendingUp,
        mdiCurrencyUsd,
        mdiCashMultiple,
        mdiMotorbikeElectric,
        mdiSendCircleOutline,
      },
      labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],

      labelsVehicles: [],
      quantityVehicles: [],

      dataCommission: [
        {
          statTitle: 'Vendas',
          icon: mdiTrendingUp,
          color: 'success',
          value: 0,
          id: 'sales',
          router: 'commissions-home',
        },
        {
          statTitle: 'Comissões',
          icon: mdiCurrencyUsd,
          color: 'info',
          value: 0,
          id: 'commission',
          router: 'integrator-commissions',
        },
        {
          statTitle: 'Bonificações',
          icon: mdiCashMultiple,
          color: 'warning',
          value: 0,
          id: 'bonification',
          router: 'list-commission-quote',
        },
        {
          statTitle: 'Entrega',
          icon: mdiMotorbikeElectric,
          color: 'purple',
          value: 0,
          id: 'delivery',
          router: 'list-bonifications',
        },
      ],

      valuesGoods: [],

      statisticsCardsData: [
        {
          statTitle: 'Peças',
          icon: mdiGaugeFull,
          color: 'purple',
          subtitle: 'Faturamento de peças',
          statistics: 0,
          change: '-18%',
          id: 'pec',
        },
        {
          statTitle: 'Acessórios',
          icon: mdiWatchVibrate,
          color: 'success',
          subtitle: 'Faturamento de acessórios',
          statistics: 0,
          change: '+42%',
          id: 'acess',
        },
        {
          statTitle: 'Oficina',
          icon: mdiCogs,
          color: 'warning',
          subtitle: 'Faturamento de serviço',
          statistics: 0,
          change: '-18%',
          id: 'mec',
        },
        {
          statTitle: 'Motos',
          icon: mdiTrendingUp,
          color: 'primary',
          subtitle: 'Faturamento de motos',
          statistics: 0,
          change: '-18%',
          id: 'mot',
        },
      ],
      itemsGraphSalesYearlyCurrent: '',
      itemsGraphSalesYearlyOld: '',
    }
  },
  async mounted() {
    await this.getDataSales()
    await this.getDataCommission()
    await this.getDataBonification()
    await this.getDataDelivery()

    // await this.getDataCommission()
    // await this.getDataBonification()
  },

  created() {
    this.getItemsSalesYearly()

    // Dados do gráfico de vendas de motos
    this.chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }
    this.latestBarChart = {
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#ffcf5c',
            borderColor: 'transparent',
            barThickness: 15,
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: this.chartColors.tooltipShadow,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: this.chartColors.grid_line_color,
                zeroLineColor: this.chartColors.grid_line_color,
              },
              ticks: {
                stepSize: 2,
                min: 0,
                max: 100,
                fontColor: this.chartColors.labelColor,
              },
            },
          ],
        },
      },
    }
  },

  methods: {
    onInput() {
      const filialName = this.itemSearch
      const referenceMonth = this.itemSearchDate

      if (!filialName || !referenceMonth) return

      this.getDataSalesInputSelected()
    },

    getDataYearly() {
      const body = {
        region: this.itemSearch,
      }
      axiosIns.post('/api/v1/integration/contract/annual_sales_manager', body).then(res => {
        this.itemsGraphSalesYearlyCurrent = []
        this.itemsGraphSalesYearlyCurrent.push(res.data.data)
      })

      axiosIns.post('/api/v1/integration/contract/annual_sales_manager_old', body).then(res => {
        this.itemsGraphSalesYearlyOld = []
        this.itemsGraphSalesYearlyOld.push(res.data.data)
      })
    },

    async getDataSalesInputSelected() {
      const body = {
        region: this.itemSearch,
        reference: this.itemSearchDate,
      }

      const bodyDeliveries = {
        invoice_date: this.itemSearchDate,
        region: this.itemSearch,
      }

      await axiosIns.post('/api/v1/integration/contract/sales_region', body).then(res => {
        this.dataCommission[0].value = Number(res.data.data[0].sales)
      })

      await axiosIns.post('/api/v1/integration/contract/commission_region', body).then(res => {
        this.dataCommission[1].value = Number(res.data.data[0].commission)
      })
      await axiosIns.post('/api/v1/integration/contract/bonus_second_fourth_parcel', body).then(res => {
        this.dataCommission[2].value = Number(res.data.data[0].total)
      })
      await axiosIns.post('/api/v1/integration/delivery/deliveries_region', bodyDeliveries).then(res => {
        this.dataCommission[3].value = Number(res.data.data[0].deliveries_value)
      })
    },

    async getItemsSalesYearly() {
      const body = {
        region: this.itemSearch,
      }

      await axiosIns.post('/api/v1/integration/contract/annual_sales_controller', body).then(res => {
        const valuesBonificationImp = []
        const valuesBonificationAca = []
        const valuesBonificationGra = []

        res.data.data.map(item => {
          if (item.region === 'IMPERATRIZ') {
            valuesBonificationImp.push(item.sum)
          } else if (item.region === 'AÇAILÂNDIA') {
            valuesBonificationAca.push(item.sum)
          } else {
            valuesBonificationGra.push(item.sum)
          }
        })

        this.dataValueSalesImp = valuesBonificationImp
        this.dataValueSalesAca = valuesBonificationAca
        this.dataValueSalesGra = valuesBonificationGra
      })
    },

    async getSalesMotocyclesMonthly() {
      const body = {
        region: this.itemSearch,
        reference: this.itemSearchDate,
      }

      const labelsData = []
      const totalData = []

      await axiosIns.post('/api/v1/integration/contract/best_selling_models_controller', body).then(res => {
        res.data.data.map(item => {
          labelsData.push(item.vehicle_model)
          totalData.push(item.total)
          this.latestBarChart = {
            data: {
              labels: labelsData,
              datasets: [
                {
                  data: totalData,
                  backgroundColor: '#ffcf5c',
                  borderColor: 'transparent',
                  barThickness: 30,
                },
              ],
            },
            options: {
              elements: {
                rectangle: {
                  borderWidth: 2,
                  borderSkipped: 'bottom',
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              responsiveAnimationDuration: 500,
              legend: {
                display: false,
              },
              tooltips: {
                // Updated default tooltip UI
                shadowOffsetX: 1,
                shadowOffsetY: 1,
                shadowBlur: 8,
                shadowColor: this.chartColors.tooltipShadow,
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: true,
                      color: this.chartColors.grid_line_color,
                      zeroLineColor: this.chartColors.grid_line_color,
                    },
                    scaleLabel: {
                      display: false,
                    },
                    ticks: {
                      fontColor: this.chartColors.labelColor,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      color: this.chartColors.grid_line_color,
                      zeroLineColor: this.chartColors.grid_line_color,
                    },
                    ticks: {
                      stepSize: 5,
                      min: 0,
                      max: 300,
                      fontColor: this.chartColors.labelColor,
                    },
                  },
                ],
              },
            },
          }
        })
      })
    },

    async getDataCommission() {
      const body = {
        region: '',
        reference: '',
      }
      await axiosIns.post('/api/v1/integration/contract/commission_region', body).then(res => {
        this.dataCommission[1].value = Number(res.data.data[0].commission)
      })
    },
    async getDataSales() {
      const body = {
        region: '',
        reference: '',
      }
      await axiosIns.post('/api/v1/integration/contract/sales_region', body).then(res => {
        this.dataCommission[0].value = Number(res.data.data[0].sales)
      })
    },
    async getDataBonification() {
      const body = {
        region: '',
        reference: '',
      }
      await axiosIns.post('/api/v1/integration/contract/bonus_second_fourth_parcel', body).then(res => {
        this.dataCommission[2].value = Number(res.data.data[0].total)
      })
    },

    async getDataDelivery() {
      const body = {
        region: '',
        reference: '',
      }

      await axiosIns.post('/api/v1/integration/delivery/deliveries_region', body).then(res => {
        this.dataCommission[3].value = Number(res.data.data[0].deliveries_value)
      })
    },

    // Requisições do cloud

    async getDataCloudSalesGoods() {
      const valuesList = []

      // eslint-disable-next-line no-nested-ternary
      const companies = this.itemSearch === 'GRAJAÚ'
        ? [5, 10, 12, 13]
        : this.itemSearch === 'AÇAILÂNDIA'
          ? [4, 6, 14]
          : [3, 7, 8, 9, 11]
      const dateInitial = `${this.itemSearchDate}-01`
      const dateFinal = `${this.itemSearchDate}-30`

      const body = {
        idrelatorioconfiguracao: 138,
        idrelatorioconsulta: 55,
        idrelatorioconfiguracaoleiaute: 226,
        idrelatoriousuarioleiaute: null,
        ididioma: 1,
        listaempresas: companies,
        filtros: `CodigoMercadoria=null;MarcaVeiculo=null;TipoVeiculo=null;ModeloVeiculo=null;TipoOperacao=2;Estoquista=null;Consultor=null;DataMovimentacaoInicial=${dateInitial};Pessoa=null;Vendedor=null;TipoMovimentoUsuario=55,56,57,236,265,294,323,352,381,410,439,468,497,526,555,584,31,32,33,237,266,295,324,353,382,411,440,469,498,527,556,585,677,678,679,680,681,682,683,684,685,686,687,688,689,690,691,692,52,53,54,267,296,325,354,383,412,441,470,499,528,557,238,586,28,29,30,222,251,280,309,338,367,396,425,454,483,512,541,570,58,59,60,252,281,310,339,368,397,426,455,484,513,542,223,571,355,34,35,36,239,268,297,326,384,413,442,471,500,529,558,587,40,41,42,220,249,278,307,365,394,423,452,481,510,539,336,568;MarcaMercadoria=null;TributacaoEstadual=null;Gerarsaldos=False;DataMovimentacaoFinal=${dateFinal};RegimeMonofasico=null;TipoMovimentoInterno=31,11,5,3,29,30,33,1;TipoMercadoria=2,3,4,5;TipoLocalizacao=null`,
      }

      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNjRmYmM4MS0zOTY4LTQ3YjYtODkyZC04NWU0MDQ3MTVkNzAiLCJvcmciOiJvcmcwMDAxMjEifQ.VEDLqbRglJY-MWcwlOVCjO2BHtwxG9rYzUwz1z4EB4M'

      await axios
        .post('https://microworkcloud.com.br/api/integracao/terceiro', body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res => {
          res.data.map(item => {
            valuesList.push(item.valortotal)
          })
        })

      const sumValues = valuesList.reduce((sum, index) => sum + index)

      this.statisticsCardsData[0].statistics = sumValues
    },

    async getDataSalesAccess() {
      const valuesList = []

      // eslint-disable-next-line no-nested-ternary
      const companies = this.itemSearch === 'GRAJAÚ'
        ? [5, 10, 12, 13]
        : this.itemSearch === 'AÇAILÂNDIA'
          ? [4, 6, 14]
          : [3, 7, 8, 9, 11]
      const dateInitial = `${this.itemSearchDate}-01`
      const dateFinal = `${this.itemSearchDate}-30`

      const body = {
        idrelatorioconfiguracao: 138,
        idrelatorioconsulta: 55,
        idrelatorioconfiguracaoleiaute: 226,
        idrelatoriousuarioleiaute: null,
        ididioma: 1,
        listaempresas: companies,
        filtros: `CodigoMercadoria=null;MarcaVeiculo=null;TipoVeiculo=null;ModeloVeiculo=null;TipoOperacao=2;Estoquista=null;Consultor=null;DataMovimentacaoInicial=${dateInitial};Pessoa=null;Vendedor=null;TipoMovimentoUsuario=597,598,599,600,601,602,603,604,605,606,607,608,609,610,611,612,55,56,57,236,265,294,323,352,381,410,439,468,497,526,555,584,31,32,33,237,266,295,324,353,382,411,440,469,498,527,556,585,677,678,679,680,681,682,683,684,685,686,687,688,689,690,691,692,52,53,54,267,296,325,354,383,412,441,470,499,528,557,238,586,28,29,30,222,251,280,309,338,367,396,425,454,483,512,541,570,58,59,60,252,281,310,339,368,397,426,455,484,513,542,223,571,355,34,35,36,239,268,297,326,384,413,442,471,500,529,558,587,40,41,42,220,249,278,307,365,394,423,452,481,510,539,336,568;MarcaMercadoria=null;TributacaoEstadual=null;Gerarsaldos=False;DataMovimentacaoFinal=${dateFinal};RegimeMonofasico=null;TipoMovimentoInterno=35,31,11,5,3,29,30,33,1;TipoMercadoria=1,16,6,15;TipoLocalizacao=null`,
      }

      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNjRmYmM4MS0zOTY4LTQ3YjYtODkyZC04NWU0MDQ3MTVkNzAiLCJvcmciOiJvcmcwMDAxMjEifQ.VEDLqbRglJY-MWcwlOVCjO2BHtwxG9rYzUwz1z4EB4M'

      await axios
        .post('https://microworkcloud.com.br/api/integracao/terceiro', body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res => {
          res.data.map(item => {
            valuesList.push(item.valortotal)
          })
        })

      const sumValues = valuesList.reduce((sum, index) => sum + index)

      this.statisticsCardsData[1].statistics = sumValues
    },

    async getDataServices() {
      const valuesList = []

      // eslint-disable-next-line no-nested-ternary
      const companies = this.itemSearch === 'GRAJAÚ'
        ? [5, 10, 12, 13]
        : this.itemSearch === 'AÇAILÂNDIA'
          ? [4, 6, 14]
          : [3, 7, 8, 9, 11]
      const dateInitial = `${this.itemSearchDate}-01`
      const dateFinal = `${this.itemSearchDate}-30`

      const body = {
        idrelatorioconfiguracao: 188,
        idrelatorioconsulta: 95,
        idrelatorioconfiguracaoleiaute: 530,
        idrelatoriousuarioleiaute: null,
        ididioma: 1,
        listaempresas: companies,
        filtros: `NumeroOS=null;Tiposervico=null;VeiculoCliente=null;Municipio=null;Tipoitem=1,2;Pessoa=null;NaoIncluirPessoa=null;Modelo=null;Periododeconclusaofinal=${dateFinal};Consultor=null;Periododeconclusaoinicial=${dateInitial};Tecnico=null;AnoInicial=0;AnoFinal=9999;ItensServicosCancelados=False;TipoRecepcao=null;EstadoVeiculo=null;Tipodeordemdeservico=null;TipoOrdemServicoInterno=null;TipoVeiculoOS=null;TipoBaixaDocumento=null;NomeEmissaoDocumento=null;SituacaoConcluidaNF=null;IdsMercadorias=null;IdsServicos=null;TipoDeVeiculoModelo=null;NumeroContratoFrotista=;EquipeAtendimentoFrotista=null;GrupoDoModelo=null;SomenteManutencaoFrotista=False;Segmento=null`,
      }

      const accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJmNjRmYmM4MS0zOTY4LTQ3YjYtODkyZC04NWU0MDQ3MTVkNzAiLCJvcmciOiJvcmcwMDAxMjEifQ.VEDLqbRglJY-MWcwlOVCjO2BHtwxG9rYzUwz1z4EB4M'

      await axios
        .post('https://microworkcloud.com.br/api/integracao/terceiro', body, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(res => {
          res.data.map(item => {
            valuesList.push(item.totalservico)
          })
        })

      const sumValues = valuesList.reduce((sum, index) => sum + index)

      this.statisticsCardsData[2].statistics = sumValues
    },
  },
}
</script>
<style>
.backdrop {
  background: #fff;
}
</style>
