<template>
  <v-card class="pl-5 mt-6">
    <v-card-title>
      Comparativo de número de cotas vendidas
      {{ region }}
    </v-card-title>
    <v-row class="mt-6">
      <v-col>
        <chartjs-component-line-chart
          :key="data"
          :height="100"
          :data="lineChart.data"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import themeConfig from '@themeConfig'
import ChartjsComponentLineChart from '../../sigma/Charts/ChartjsComponentLineChart.vue'

export default {
  components: {
    ChartjsComponentLineChart,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    old: {
      type: Array,
      required: true,
    },
    region: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      lineChart: {},
      itemsGraph: [],
      itemsGraphOld: [],
    }
  },

  created() {
    const $themeColors = themeConfig.themes.light

    const chartColors = {
      primaryColorShade: '#836AF9',
      yellowColor: '#ffe800',
      successColorShade: '#28dac6',
      warningColorShade: '#ffe802',
      warningLightColor: '#FDAC34',
      infoColorShade: '#299AFF',
      greyColor: '#4F5D70',
      blueColor: '#2c9aff',
      blueLightColor: '#84D0FF',
      greyLightColor: '#EDF1F4',
      tooltipShadow: 'rgba(0, 0, 0, 0.25)',
      lineChartPrimary: '#666ee8',
      lineChartDanger: '#ff4961',
      labelColor: '#6e6b7b',
      grid_line_color: 'rgba(200, 200, 200, 0.2)',
    }

    this.data[0].map(item => {
      this.itemsGraph.push(item.sales)
    })

    this.old[0].map(item => {
      this.itemsGraphOld.push(item.sales)
    })

    this.lineChart = {
      options: {
        responsive: true,
        maintainAspectRatio: false,
        backgroundColor: false,
        hover: {
          mode: 'label',
        },
        tooltips: {
        // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: chartColors.tooltipShadow,
        },
        layout: {
          padding: {
            top: -15,
            bottom: -25,
            left: -15,
          },
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
              ticks: {
                fontColor: chartColors.labelColor,
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              ticks: {
                stepSize: 100,
                min: 0,
                max: 400,
                fontColor: chartColors.labelColor,
              },
              gridLines: {
                display: true,
                color: chartColors.grid_line_color,
                zeroLineColor: chartColors.grid_line_color,
              },
            },
          ],
        },
        legend: {
          position: 'top',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 9,
          },
        },
      },
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.itemsGraph,
            label: 'Atual',
            borderColor: chartColors.primaryColorShade,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: chartColors.primaryColorShade,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: chartColors.primaryColorShade,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            // eslint-disable-next-line no-nested-ternary
            data: this.region === 'IMPERATRIZ' ? [797, 708, 765, 808, 828, 816, 840, 901, 795, 784, 856, 669]
              : this.region === 'GRAJAÚ' ? [166, 162, 212, 183, 253, 220, 215, 227, 213, 207, 230, 226] : [],
            label: 'Meta',
            borderColor: $themeColors.warning,
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: $themeColors.warning,
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: $themeColors.warning,
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
          {
            data: this.itemsGraphOld,
            label: 'Ano anterior',
            borderColor: '#d4e157',
            lineTension: 0.5,
            pointStyle: 'circle',
            backgroundColor: '#d4e157',
            fill: false,
            pointRadius: 1,
            pointHoverRadius: 5,
            pointHoverBorderWidth: 5,
            pointBorderColor: 'transparent',
            pointHoverBackgroundColor: '#d4e157',
            pointShadowOffsetX: 1,
            pointShadowOffsetY: 1,
            pointShadowBlur: 5,
            pointShadowColor: chartColors.tooltipShadow,
          },
        ],
      },
    }
  },
}
</script>
