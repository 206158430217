<template>
  <v-col
    class="d-flex align-center"
  >
    <v-avatar
      :color="infos.color"
      size="50"
      rounded
      :class="`v-avatar-light-bg ${infos.color}--text`"
    >
      <v-icon
        dark
        :color="infos.color"
        size="40"
        @click="$router.push({ name: infos.router })"
      >
        {{ infos.icon }}
      </v-icon>
    </v-avatar>
    <div class="ms-3">
      <p class="mb-0">
        {{ infos.statTitle }}
      </p>
      <h3
        :id="infos.id"
        class="text-xl font-weight-semibold"
      >
        {{ infos.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
      </h3>
    </div>
  </v-col>
</template>
<script>
export default {
  props: {
    infos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {

    }
  },

  // async created() {
  //   await this.counterDataStatistics(0, this.infos.value)
  // },

  methods: {
    clickIcon() {
    },
    counterDataStatistics(initial, maximum) {
      const number = document.getElementById(this.infos.id)
      if (initial > maximum) return
      setTimeout(() => {
        number.innerHTML = initial.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
        this.counterDataStatistics(initial + (maximum / 100), maximum)
      }, 5)
    },

  },
}
</script>
