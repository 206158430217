import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"pb-3 mb-4"},[_c(VCardTitle,{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Estatísticas")])]),_c(VCardSubtitle,{staticClass:"mb-4 mt-n1"},[_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v("Dados das vendas de consórcio 💰")])]),_c(VRow,{staticClass:"ml-3"},[_c(VCol,{attrs:{"cols":"3"}},[_c(VAutocomplete,{attrs:{"dense":"","outlined":"","items":_vm.listRegionsItems,"label":"Filial"},model:{value:(_vm.itemSearch),callback:function ($$v) {_vm.itemSearch=$$v},expression:"itemSearch"}})],1),_c(VCol,{attrs:{"cols":"3"}},[_c(VMenu,{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VTextField,_vm._g(_vm._b({attrs:{"dense":"","outlined":"","label":"Mês referência","hide-details":"","readonly":""},model:{value:(_vm.itemSearchDate),callback:function ($$v) {_vm.itemSearchDate=$$v},expression:"itemSearchDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c(VDatePicker,{attrs:{"type":"month","width":"270","locale":"pt-BR"},model:{value:(_vm.itemSearchDate),callback:function ($$v) {_vm.itemSearchDate=$$v},expression:"itemSearchDate"}})],1)],1),_c(VCol,{attrs:{"cols":"1"}},[_c(VBtn,{attrs:{"variant":"tonal","color":"info"},on:{"click":function($event){_vm.getDataSalesInputSelected(),
            _vm.getSalesMotocyclesMonthly(),
            _vm.getDataCloudSalesGoods(),
            _vm.getDataSalesAccess(),
            _vm.getDataServices(),
            _vm.getDataYearly()}}},[_c(VIcon,{attrs:{"size":"30"}},[_vm._v(" "+_vm._s(_vm.icon.mdiSendCircleOutline)+" ")])],1)],1)],1),_c(VCardText,[_c(VRow,_vm._l((_vm.dataCommission),function(data){return _c(VCol,{key:data.value,staticClass:"d-flex align-center"},[_c('statistics-payments',{key:data.value,attrs:{"infos":data}})],1)}),1)],1)],1),_c(VCard,[_c(VRow,{staticClass:"d-flex mt-5"},[_c(VCol,{attrs:{"md":"6"}},[_c(VCardText,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Vendas")])]),_c(VCardSubtitle,{staticClass:"mb-8 mt-n1"},[_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v("Curva de valores de vendas 💸")])]),_c('chart-commission-yearly',{key:_vm.dataValueSalesImp,attrs:{"labels":_vm.labels,"values-imp":_vm.dataValueSalesImp,"values-aca":_vm.dataValueSalesAca,"values-gra":_vm.dataValueSalesGra}})],1)],1),_c(VCol,{attrs:{"md":"6"}},[_c(VCardText,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v("Motos")])]),_c(VCardSubtitle,{staticClass:"mb-8 mt-n1"},[_c('span',{staticClass:"font-weight-semibold text--primary me-1"},[_vm._v("Modelos mais vendidos no mês 🤩")])]),_c('chartjs-component-bar-chart',{key:_vm.latestBarChart.data.labels,attrs:{"height":440,"data":_vm.latestBarChart.data,"options":_vm.latestBarChart.options}})],1)],1)],1)],1),_c(VRow,{staticClass:"mt-6"},_vm._l((_vm.statisticsCardsData),function(statistics){return _c(VCol,{key:statistics.id},[_c('statistics-card',{key:statistics.statistics,attrs:{"infos":statistics}})],1)}),1),_c('chart-sales-yearly',{key:(_vm.itemsGraphSalesYearlyCurrent, _vm.itemsGraphSalesYearlyOld),attrs:{"labels":_vm.labels,"data":_vm.itemsGraphSalesYearlyCurrent,"old":_vm.itemsGraphSalesYearlyOld,"region":_vm.itemSearch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }