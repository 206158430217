<script>
import { Line } from 'vue-chartjs'

export default {
  components: {
    Line,
  },
  extends: Line,
  props: {
    labels: {
      type: Array,
      required: true,
    },
    valuesImp: {
      type: Array,
      required: true,
    },
    valuesAca: {
      type: Array,
      required: true,
    },
    valuesGra: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      gradient: null,
      gradient2: null,
      gradient3: null,
    }
  },
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient2 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)
    this.gradient3 = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)')
    this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)')
    this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)')

    this.gradient2.addColorStop(0, 'rgba(0, 231, 255, 0.9)')
    this.gradient2.addColorStop(0.5, 'rgba(0, 231, 255, 0.25)')
    this.gradient2.addColorStop(1, 'rgba(0, 231, 255, 0)')

    this.gradient3.addColorStop(0, 'rgba(0, 145, 22, 0.9)')
    this.gradient3.addColorStop(0.5, 'rgba(0, 145, 22, 0.25)')
    this.gradient3.addColorStop(1, 'rgba(0, 145, 22, 0)')

    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: 'Imperatriz',
            borderColor: '#FC2525',
            pointBackgroundColor: 'white',
            borderWidth: 1,
            pointBorderColor: 'white',
            backgroundColor: this.gradient,
            data: this.valuesImp,
            strokeColor: '#FFF',
            pointColor: '#FFF',
          },
          {
            label: 'Açailândia',
            borderColor: '#05CBE1',
            pointBackgroundColor: 'white',
            pointBorderColor: 'white',
            borderWidth: 1,
            backgroundColor: this.gradient2,
            data: this.valuesAca,
          },
          {
            label: 'Grajaú',
            borderColor: '#56CA00',
            pointBackgroundColor: 'white',
            pointBorderColor: 'white',
            borderWidth: 1,
            backgroundColor: this.gradient3,
            data: this.valuesGra,
          },
        ],
        options: {
          legend: {
            labels: {
              fontColor: '#000',
            },
          },
        },

      },
      { responsive: true, maintainAspectRatio: false },
    )
  },
}
</script>
